<template>
  <div
    :class="['grid', 'grid-cols-2', 'gap-rem-16', 'overflow-hidden', 'h-full']"
  >
    <LayoutCard :title="$t('safety.deviceStatus')">
      <LiftStatus />
    </LayoutCard>
    <div class="flex flex-col gap-rem-16 overflow-hidden">
      <div>
        <LayoutCard title="升降机平面图">
          <template #action>
            <ali-icon
              type="tc-icon-zhankai"
              @click="handleFullScreen"
            ></ali-icon>
          </template>
          <div :class="$style.planViewWrap">
            <PlanViewCanvas></PlanViewCanvas>
          </div>
        </LayoutCard>
      </div>
      <div class="flex-1 grid grid-cols-2 gap-rem-16 overflow-hidden">
        <LayoutCard :title="['时长分析', '运行记录']">
          <template #action="{index}">
            <range-picker
              @ok="v => handleSearchTime(v, '1')"
              v-show="
                deviceRuningTimeSelectValue === 'CUSTOM_DAY' && index === 0
              "
              style="transform: translateX(0.09rem)"
            />
            <a-select
              v-show="index == 0"
              :options="daysOption"
              style="width: 1rem;transform: translateX(0.09rem)"
              v-model="deviceRuningTimeSelectValue"
              @change="runingTimeSelectChange"
            ></a-select>
          </template>
          <div class="h-full overflow-hidden" style="padding: 0.16rem;">
            <LineChart
              :isSmooth="false"
              symbol="round"
              chartRight="2%"
              :unit="['s']"
              :chart-data="deviceRuningTimeChartOption"
              :show-legend="true"
            />
          </div>
          <div class="h-full overflow-hidden" style="padding: 0.16rem;">
            <ScrollTable
              ref="runingLogRef"
              :columns="runingLogTable.columns"
              :data="runingLogTable.data"
              @cellClick="runingLogClick"
              infiniteScroll
            ></ScrollTable>
          </div>
        </LayoutCard>

        <LayoutCard title="预警/报警类型">
          <template #action>
            <range-picker
              @ok="v => handleSearchTime(v, '2')"
              v-if="alertSelectValue === 'CUSTOM_DAY'"
            />
            <a-select
              :options="daysOption"
              style="width: 1rem;"
              v-model="alertSelectValue"
              @change="alertSelectChange"
            ></a-select>
          </template>
          <div class="flex flex-col h-full overflow-hidden">
            <div class="flex justify-around" :class="$style.alertTitle">
              <div class="flex items-center gap-rem-8">
                <span :class="$style.alertLabel">总预警</span>
                <span :class="$style.alertValue">{{ wranTotal }}</span>
              </div>
              <div class="flex items-center gap-rem-8">
                <span :class="$style.alertLabel">总报警</span>
                <span :class="$style.alertValue">{{ alarmTotal }}</span>
              </div>
            </div>
            <div class="flex-1 overflow-hidden">
              <PieChart
                left="10%"
                right="10%"
                :chart-data="alertAnalyzeChartData"
                :labelOption="labelOption"
              ></PieChart>
            </div>
          </div>
        </LayoutCard>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { LayoutCard, LayoutSelect } from '../../../layout';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import LiftStatus from './lift-status.vue';
import LineChart from '@/views/screen/e-charts/line-chart';
import { nowSize } from '@/utils/common';
import PieChart from '@/views/screen/e-charts/pie-chart.vue';
import AliIcon from '@/components/ali-icon/index.vue';
import PlanViewCanvas from './components/canvas';
import {
  getLiftAlarmStatics,
  liftRuningTimeStatics,
  runingLog,
} from '@/services/screen/jiangsu/common';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';
import moment from 'moment';
import { createModal } from '@triascloud/x-components';
import { Emitter } from '@/views/screen/components/screen-utils';
import DataListModal from './components/data-list.vue';
import RangePicker from '@/views/screen/components/range-picker.vue';

@Component({
  components: {
    LayoutCard,
    LayoutSelect,
    ScrollTable,
    LiftStatus,
    LineChart,
    PieChart,
    AliIcon,
    PlanViewCanvas,
    RangePicker,
  },
})
export default class LiftIndex extends Vue {
  deviceGroup = deviceTypeEnum[pageTypeEnum.towerCrane].deviceGroup;
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  daysOption = [
    {
      label: '自定义',
      value: 'CUSTOM_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];
  colorArray = ['#6BBDFF'];
  scrollTbaleColumns = [
    {
      title: '设备名称',
      key: 'deviceName',
      width: '20%',
    },
    {
      title: '设备码',
      key: 'deviceCode',
      width: '20%',
    },
    {
      title: '运行状态',
      key: 'moveStatus',
      width: '20%',
      format: val => ['停止', '向上', '向下'][val] ?? '-',
    },
    {
      title: '运行时间',
      key: 'faceTime',
      format: val => moment(val).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  // 获取设备列表
  deviceId = '';
  deviceSelectChange(deviceId) {
    this.deviceId = deviceId;
    this.initFetch();
    this.refresh();
  }

  // 每30S刷新事件
  timer = null;
  refresh() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.initFetch();
      Emitter.$emit('refresh');
      this.refresh();
    }, 30000);
  }

  // 设备运行时长分析
  deviceRuningTimeSelectValue = 'SEVEN_DAY';
  deviceRuningTimeData = {
    dataList: [],
    times: [],
  };
  get deviceRuningTimeChartOption() {
    const { times, dataList } = this.deviceRuningTimeData;
    return [
      {
        time: times.map(e => moment(e).valueOf()),
        data: dataList?.map(item => ({
          name: item.tag,
          data: item.values.map(value => ({
            value: +value === -1 ? 0 : value,
            unit: 's',
          })),
        })),
      },
    ];
  }
  async getLiftRuningTimeStatics(startTime = undefined, endTime = undefined) {
    this.deviceRuningTimeData = await liftRuningTimeStatics({
      days: this.deviceRuningTimeSelectValue,
      projectId: this.projectId,
      deviceId: this.deviceId,
      startTime,
      endTime,
    });
  }
  runingTimeSelectChange() {
    this.deviceRuningTimeSelectValue !== 'CUSTOM_DAY' &&
      this.getLiftRuningTimeStatics();
  }

  // 运行记录
  runingLogTable = {
    columns: [
      {
        title: '设备名称',
        key: 'deviceName',
        width: '20%',
      },
      {
        title: '设备码',
        key: 'deviceCode',
        width: '20%',
      },
      {
        title: '运行状态',
        key: 'moveStatus',
        width: '20%',
        format: val => ['停止', '向上', '向下'][val] ?? '-',
      },
      {
        title: '运行时间',
        key: 'faceTime',
        format: val => moment(val).format('YYYY-MM-DD HH:mm:ss'),
      },
    ],
    data: [],
  };
  async getRuningLog() {
    const { records } = await runingLog({
      size: 10,
      current: 1,
      idxProjectId: this.projectId,
      deviceId: this.deviceId,
    });
    this.runingLogTable.data = records;
  }
  runingLogClick() {
    createModal(() => <DataListModal projectId={this.projectId} />, {
      title: '升降机运行记录',
      className: 'screen-modal',
      width: '80vw',
    });
  }

  /**@name 预/报警类型分析 */
  alertSelectValue = 'SEVEN_DAY';
  alertData = [];
  get wranTotal() {
    const format = val => (+val > 0 ? +val : 0);
    return this.alertData
      .filter(item => +item.keyType === 1)
      .reduce((a, b) => a + format(b.count), 0);
  }
  get alarmTotal() {
    const format = val => (+val > 0 ? +val : 0);
    return this.alertData
      .filter(item => +item.keyType === 2)
      .reduce((a, b) => a + format(b.count), 0);
  }
  get alertAnalyzeChartData() {
    const format = val => (+val > 0 ? +val : 0);
    const data = this.alertData;
    return data.map(item => ({
      value: format(item.count),
      name: item.typeName,
    }));
  }
  get labelOption() {
    const total = this.alertAnalyzeChartData.reduce((a, b) => a + b.value, 0);
    return {
      show: true,
      position: 'center',
      formatter: `{a|总}\n{b|${total}}`,
      rich: {
        a: {
          color: '#D8E1F3',
          fontSize: nowSize(14),
        },
        b: {
          color: '#fff',
          textShadowColor: '#7DB6FF',
          textShadowBlur: 8,
          fontSize: nowSize(20),
          padding: [nowSize(8), 0, 0, 0],
        },
      },
    };
  }
  async getLiftAlarmStatics(startTime = undefined, endTime = undefined) {
    this.alertData = await getLiftAlarmStatics({
      projectId: this.projectId,
      days: this.alertSelectValue,
      startTime,
      endTime,
    });
  }
  alertSelectChange() {
    this.alertSelectValue !== 'CUSTOM_DAY' && this.getLiftAlarmStatics();
  }

  // 时间筛选
  rangDate = [];
  handleSearchTime(time, type) {
    const startTime = this.$moment(time[0]).valueOf();
    const endTime = this.$moment(time[1]).valueOf();
    this.rangDate = [startTime, endTime];
    if (type === '1') {
      this.getLiftRuningTimeStatics(startTime, endTime);
    } else if (type === '2') {
      this.getLiftAlarmStatics(startTime, endTime);
    }
  }

  handleFullScreen() {
    createModal(
      () => (
        <div
          class={'w-full flex justify-center items-center'}
          style={'aspect-ratio: 16/9;'}
        >
          <PlanViewCanvas isModal />
        </div>
      ),
      {
        width: '80vw',
        wrapClassName: 'konva-wrapper',
      },
    );
  }

  initFetch() {
    if (this.deviceRuningTimeSelectValue === 'CUSTOM_DAY') {
      this.getLiftRuningTimeStatics(this.rangDate[0], this.rangDate[1]);
    } else {
      this.getLiftRuningTimeStatics();
    }
  }

  created() {
    Emitter.$on('deviceChange', this.deviceSelectChange);
    this.getLiftAlarmStatics();
    this.getRuningLog();
  }

  beforeDestroy() {
    Emitter.$off('deviceChange', this.deviceSelectChange);
    clearTimeout(this.timer);
  }
}
</script>

<style lang="less" module>
.alertTitle {
  padding: 0.16rem;
  font-family: 'YouSheBiaoTiHei';
  .alertLabel {
    color: rgba(255, 255, 255, 0.5);
  }

  .alertValue {
    font-size: 0.24rem;
  }
}
:global(.konva-wrapper .ant-modal-body) {
  margin: 0;
  padding: 0;
}
.planViewWrap {
  height: 45vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
