<template>
  <div>
    <div :class="$style.search">
      <div :class="$style.itemWrap">
        <div :class="$style.item">
          <a-range-picker
            :show-time="{
              defaultValue: [
                $moment('00:00:00', 'HH:mm:ss'),
                $moment('23:59:59', 'HH:mm:ss'),
              ],
            }"
            format="YYYY-MM-DD HH:mm:ss"
            @ok="onChangeTimeRange"
            @change="initData"
          />
        </div>
      </div>
      <span style="color: var(--screen-font-3);">
        人脸识别上下机次数：{{ pagination.total }}
      </span>
    </div>
    <a-table
      style="height: 5rem"
      @change="handlePagination"
      :columns="columns"
      :dataSource="datasource"
      :pagination="pagination"
      :loading="loading"
      :scroll="{ x: '100%', y: 'max-content' }"
      :transformCellText="({ text }) => text || '-'"
      row-key="pkId"
    ></a-table>
  </div>
</template>

<script>
import { Component, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import AsyncSelect from '@/components/async-select';
import { getUpDownLog } from '@/services/screen/jiangsu/common';
import PaginationTable from '@/components/pagination-table';
import PaginationMixin from '../../../../project/mixin/pagination';
import { mixins } from 'vue-class-component';
import moment from 'moment';
import OssImage from '@/views/screen/components/oss-image.vue';

@Component({ components: { EmptyContent, AsyncSelect, PaginationTable } })
export default class DeviceLog extends mixins(PaginationMixin) {
  @Prop({ type: String, default: '' }) deviceId;

  searchParams = { deviceId: '' };
  api(params) {
    return getUpDownLog(params, 'elevator');
  }
  get columns() {
    return [
      {
        title: '序号',
        width: 70,
        customRender: (text, row, index) => {
          const { current, pageSize } = this.pagination;
          return (current - 1) * pageSize + index + 1;
        },
      },
      {
        title: '设备名称',
        dataIndex: 'deviceName',
        width: 180,
        ellipsis: true,
      },
      {
        title: '设备码',
        dataIndex: 'deviceCode',
        width: 180,
        ellipsis: true,
      },
      {
        align: 'left',
        title: '抓拍图片',
        dataIndex: 'capture',
        width: 180,
        customRender: text => (text ? <OssImage ossPath={text} /> : ''),
      },
      {
        title: '司机名称',
        dataIndex: 'driverName',
        width: 150,
        ellipsis: true,
      },
      {
        title: '司机身份证号',
        dataIndex: 'driverIdentityNumber',
        width: 250,
        ellipsis: true,
      },
      {
        title: '上下机',
        dataIndex: 'type',
        width: 100,
        ellipsis: true,
        customRender: text => {
          const success = <div class={this.$style.successText}>上机</div>;
          const error = <div class={this.$style.errorText}>下机</div>;

          return [success, error][text] ?? '-';
        },
      },
      {
        title: '上下机时间',
        dataIndex: 'faceTime',
        width: 180,
        ellipsis: true,
        customRender: text => moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
    ];
  }

  created() {
    this.searchParams.deviceId = this.deviceId;
  }

  onChangeTimeRange(time) {
    this.searchParams.startTime = this.$moment(time[0]).valueOf();
    this.searchParams.endTime = this.$moment(time[1]).valueOf();
    this.pagination.current = 1;
    this.fetch();
  }

  initData(v) {
    if (v.length === 0) {
      this.searchParams.startTime = '';
      this.searchParams.endTime = '';
      this.pagination.current = 1;
      this.fetch();
    }
  }
}
</script>

<style lang="less" module>
.successText {
  color: var(--screen-success);
}
.errorText {
  color: var(--screen-error);
}
.search {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  .itemWrap {
    display: flex;
  }
  .item {
    margin-right: 0.15rem;
  }
}
</style>
