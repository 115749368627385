<template>
  <div class="containerSvg">
    <div class="svgWrap">
      <img class="hulanSvg" src="./svg/hulan.svg" alt="" />
      <img :class="['liftSvg', animationName]" src="./svg/lift.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AnimationSvg extends Vue {
  //上升：bottomToTop; 下降：topToBottom
  @Prop({ type: String, default: 'static' }) animationName;
}
</script>
<style lang="less" scoped>
.containerSvg {
  position: relative;
  background: url('~@/assets/images/theme/project/lift-background.png')
    no-repeat center;
  background-size: 6.4rem auto;
  height: 100%;
  width: 100%;
  .svgWrap {
    right: 3.051rem;
    position: absolute;
    width: max-content;
    height: 100%;
    width: 1.35rem;
  }
  .liftSvg {
    position: absolute;
    left: 0.08rem;
    // right: 2.75rem;
    /* 2、动画持续时间 */
    animation-duration: 2s;
    /* 3、动画执行速度 */
    animation-timing-function: linear;
    animation-iteration-count: 1;
    /* 6、设置动画是否反向播放 */
    animation-direction: alternate;
    /* 7、动画结束时应用最后一帧 */
    animation-fill-mode: forwards;
  }
  .bottomToTop {
    animation-name: bottomToTop;
  }
  .topToBottom {
    animation-name: topToBottom;
  }
  .static {
    bottom: 0.18rem;
  }
  .hulanSvg {
    position: absolute;
    bottom: 0.06rem;
    // right: 2.7rem;
  }
}
@keyframes bottomToTop {
  /* 开始 */
  from {
    bottom: 0.18rem;
  }

  to {
    bottom: 2.5rem;
  }
}
@keyframes topToBottom {
  /* 开始 */
  from {
    bottom: 2.5rem;
  }

  to {
    bottom: 0.18rem;
  }
}
</style>
