<template>
  <div>
    <div class="flex gap-8" style="margin-bottom: 0.1rem;">
      <a-select
        placeholder="全部设备"
        :options="deviceOption"
        v-model="params.deviceId"
        @change="selectChange"
        style="width: 2rem;"
        allowClear
      />
    </div>
    <div style="height: 50vh">
      <PaginationTable
        ref="tableRef"
        row-key="pkId"
        :api="api"
        :columns="columns"
        :scroll="{ x: false, y: 'max-content' }"
        :paginationConfig="{
          size: 'small',
          showQuickJumper: false,
        }"
      />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import AsyncSelect from '@/components/async-select';
import PaginationTable from '@/components/pagination-table';
import moment from 'moment';
import { LayoutSelect } from '@/views/screen/layout';
import { getDeviceLists } from '@/services/screen/jiangsu/vehicle';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';
import { runingLog } from '@/services/screen/jiangsu/common';

@Component({
  components: { EmptyContent, AsyncSelect, PaginationTable, LayoutSelect },
})
export default class DataListModal extends Vue {
  @Prop({ required: true }) projectId;

  deviceGroup = deviceTypeEnum[pageTypeEnum.liftMonitor].deviceGroup;
  params = {
    deviceId: undefined,
  };

  deviceList = [];
  get deviceOption() {
    return this.deviceList.map(item => ({
      label: item.deviceName,
      value: item.pkId,
    }));
  }
  async getDeviceList() {
    this.deviceList = await getDeviceLists({
      deviceGroup: this.deviceGroup.toLocaleUpperCase(),
      idxProjectId: this.projectId,
    });
  }

  // 传感器
  async api({ current, size }) {
    const res = await runingLog({
      current,
      size,
      idxProjectId: this.projectId,
      ...this.params,
    });
    return res;
  }
  columns = [
    {
      title: '设备名称',
      dataIndex: 'deviceName',
    },
    {
      title: '设备码',
      dataIndex: 'deviceCode',
    },
    {
      title: '运行状态',
      dataIndex: 'moveStatus',
      customRender: val => ['停止', '向上', '向下'][val] ?? '-',
    },
    {
      title: '运行时间',
      dataIndex: 'faceTime',
      customRender: val =>
        val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : '-',
    },
  ];

  selectChange() {
    this.$refs.tableRef.search();
  }

  created() {
    this.getDeviceList();
  }
}
</script>

<style lang="less" module></style>
