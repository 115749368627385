<template>
  <div :class="$style.lift" class="flex flex-col items-center">
    <div class="w-full flex items-center" :class="$style.wrapHeader">
      <a-select
        :options="deviceOptions"
        v-model="deviceId"
        @change="handleChange"
        style="width: 1.8rem;"
      ></a-select>
      <div class="flex">
        <span :class="[$style.endTime]">最后更新时间：{{ lastTime }}</span>
      </div>
      <span :class="$style.deviceId">设备码：{{ deviceCode }}</span>
    </div>
    <div :class="$style.svg">
      <AnimationSvg :animationName="liftSvg" />
    </div>

    <div class="flex flex-col gap-y-rem-16" :class="[$style.floatBox]">
      <div @click="showDeviceInfo">设备信息</div>
      <div @click="showDriverInfo">司机信息</div>
      <div @click="showDeviceLog">上下机记录</div>
    </div>

    <div :class="$style.indicator" class="grid grid-cols-5">
      <div v-for="(item, idx) in indicator" :key="idx" :class="$style.card">
        <div :class="$style.label">
          <AliIcon :type="item.icon" :class="$style.iconStyle" />
          <div :class="$style.text">
            {{ item.text }}
          </div>
        </div>
        <div :class="$style.cardRight">
          <span :class="[$style.count, item.unit ? '' : $style.noUnitText]">{{
            item.format ? item.format(item.count) : item.count
          }}</span>
          {{ item.unit }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { liftStatus } from '@/services/screen/safety-monitor';
import AliIcon from '@/components/ali-icon/index.vue';
import AnimationSvg from '@/views/screen/project/safety/lift/animation-svg.vue';
import { LayoutSelect } from '@/views/screen/layout';
import { getDeviceLists } from '@/services/screen/jiangsu/vehicle';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';
import { Emitter } from '@/views/screen/components/screen-utils';
import { createModal } from '@triascloud/x-components';
import { nowSize } from '@/utils/common';
import {
  getTowerDeviceDetail,
  getTowerDriverDetail,
} from '@/services/screen/jiangsu/common';
import DeviceInfo from '../components/device-info.vue';
import DriverInfo from '../components/driver-info.vue';
import DeviceLog from './components/device-log.vue';
import moment from 'moment';
import { PublicSize } from '@/views/green-construction/components/utils';

@Component({
  components: {
    AliIcon,
    AnimationSvg,
    LayoutSelect,
  },
})
export default class LiftStatus extends Vue {
  deviceGroup = deviceTypeEnum[pageTypeEnum.liftMonitor].deviceGroup;
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  indicator = {
    weight: {
      text: '载重量',
      icon: 'tc-icon-zaizhongliang',
      count: '-',
      unit: 't',
    },
    height: {
      text: '高度',
      icon: 'tc-icon-gaodu',
      count: '-',
      unit: 'm',
    },
    speed: {
      text: this.$t('detail.runningSpeed'),
      icon: 'tc-icon-yunhangsudu',
      count: '-',
      unit: 'm/s',
    },
    tiltXAngle: {
      text: this.$t('detail.angleX'),
      icon: 'tc-icon-qingjiao',
      count: '-',
      format: text => `${text > 0 ? text : '-'}°`,
    },
    tiltYAngle: {
      text: this.$t('detail.angleY'),
      icon: 'tc-icon-qingjiao',
      count: '-',
      format: text => `${text > 0 ? text : '-'}°`,
    },
    windSpeed: {
      text: '风速',
      icon: 'tc-icon-fengsu',
      count: '-',
      unit: 'm/s',
    },
    floor: {
      text: this.$t('detail.floor'),
      icon: 'tc-icon-louceng',
      count: '-',
      unit: '层',
    },
    population: {
      text: '运载人数',
      icon: 'tc-icon-shibierenshu',
      count: '-',
      unit: '人',
    },
    frontDoorStatus: {
      text: this.$t('detail.frontDoorStatus'),
      icon: 'tc-icon-qianhoumenzhuangtai',
      count: '-',
      unit: '',
      format: text => ['-', '打开', '关闭'][text] ?? '-',
    },
    backDoorStatus: {
      text: this.$t('detail.backDoorStatus'),
      icon: 'tc-icon-qianhoumenzhuangtai',
      count: '-',
      unit: '',
      format: text => ['-', '打开', '关闭'][text] ?? '-',
    },
  };

  // 设备
  deviceId = '';
  deviceOptions = [];
  deviceData = [];
  get deviceCode() {
    return (
      this.deviceData.find(e => this.deviceId === e.pkId)?.deviceCode ?? '-'
    );
  }
  get lastTime() {
    const lastDataTime = this.deviceStatusData.lastUpdateTime;
    return lastDataTime
      ? moment(lastDataTime).format('YYYY-MM-DD HH:mm:ss')
      : '-';
  }
  async getDeviceLists() {
    const res = await getDeviceLists({
      deviceGroup: this.deviceGroup.toLocaleUpperCase(),
      idxProjectId: this.projectId,
    });
    this.deviceData = res;
    this.deviceOptions = res.map(item => ({
      label: item.deviceName,
      value: item.pkId,
    }));
    const [{ value }] = this.deviceOptions;
    this.deviceId = value;
    Emitter.$emit('deviceChange', this.deviceId);
  }
  handleChange() {
    Emitter.$emit('deviceChange', this.deviceId);
    this.init();
  }

  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '-';
  }

  // 获取设备状态
  get liftSvg() {
    const d = this.deviceStatusData?.direction ?? 0;
    return d !== -1 ? ['static', 'bottomToTop', 'topToBottom'][d] : 'static';
  }
  deviceStatusData = {};
  async getDeviceStatus() {
    try {
      const res = (await liftStatus(this.deviceId)) ?? {};
      this.deviceStatusData = res;
      for (const key in this.indicator) {
        const val = res[key] ?? '-';
        this.indicator[key].count = val === -1 ? '-' : val;
      }
    } catch {
      return false;
    }
  }

  init() {
    this.getDeviceStatus();
    this.getTowerDriverDetail();
    this.getTowerDeviceDetail();
  }

  /** 司机信息 */
  driverDetail = {};
  async getTowerDriverDetail() {
    this.driverDetail = await getTowerDriverDetail(this.deviceId);
  }
  /** 设备信息 */
  deviceDetail = {};
  async getTowerDeviceDetail() {
    this.deviceDetail = await getTowerDeviceDetail(this.deviceId);
  }

  // 设备信息
  showDeviceInfo() {
    createModal(() => <DeviceInfo data={this.deviceDetail} />, {
      wrapClassName: 'screen-modal',
      title: '设备信息',
      maskClosable: false,
      width: nowSize(532),
    });
  }

  // 司机信息
  showDriverInfo() {
    createModal(() => <DriverInfo data={this.driverDetail} />, {
      wrapClassName: 'screen-modal',
      title: '司机信息',
      maskClosable: false,
      width: nowSize(532),
    });
  }

  // 上下机记录
  showDeviceLog() {
    createModal(
      () => (
        <DeviceLog deviceId={this.deviceId} deviceOption={this.deviceOption} />
      ),
      {
        wrapClassName: 'screen-modal',
        title: '升降机司机上下机记录',
        maskClosable: false,
        width: PublicSize.LargeLogModalSize,
      },
    );
  }

  async mounted() {
    await this.getDeviceLists();
    Emitter.$on('refresh', this.init);
    this.init();
  }
  beforeDestroy() {
    Emitter.$off('refresh', this.init);
  }
}
</script>

<style lang="less" module>
.lift {
  position: relative;
  font-size: 0.14rem;
  height: 100%;
  padding: 0.16rem;
  .person {
    position: absolute;
    width: 3.3rem;
    right: 0.1rem;
    top: 0.05rem;
    .number {
      text-align: end;
      color: #9ac0d9;
      padding-top: 0.16rem;
    }
    .head {
      display: flex;
      align-items: center;
      margin-top: 0.2rem;
      transition: all 0.3s;
      .headIcon {
        width: 0.64rem;
        height: 0.64rem;
        border-radius: 50%;
        background: #223142;
        font-size: 0.65rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #90a1b1;
      }
      .openIcon {
        height: 0.68rem;
        width: 0.21rem;
        display: flex;
        align-items: center;
        border-top-left-radius: 0.08rem;
        border-bottom-left-radius: 0.08rem;
        margin-left: 0.05rem;
        cursor: pointer;
        background: #223142;
      }
      .info {
        display: flex;
        flex-direction: column;
        margin-left: 0.1rem;
        .text {
          font-weight: 600;
          margin-bottom: 0.05rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .id {
          text-align: center;
          width: 2.1rem;
          height: 0.3rem;
          border: 0.01rem solid var(--border);
          border-radius: 0.15rem;
          background: #223142;
          color: #9ac0d9;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .time {
          color: var(--font-sub);
          margin-top: 0.05rem;
          color: #9ac0d9;
          font-size: 0.12rem;
        }
      }
    }
    .open {
      transform: translateX(0);
    }
    .close {
      transform: translateX(78%);
    }
  }
  .svg {
    height: 4rem;
    width: 100%;
    margin-top: 0.15rem;
  }
  .runningTime {
    text-align: center;
    color: #9ac0d9;
    margin-top: 0.24rem;
  }
  .indicator {
    grid-gap: 0.2rem;
    justify-content: center;
    margin-top: 0.4rem;
    .card {
      width: 1.5rem;
      aspect-ratio: 143/56;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: url('~@/assets/images/theme/card2-bg.png') center / 100%
        no-repeat;
      padding: 0 0.07rem;
      color: #fff;
      font-size: 0.12rem;
    }
    .label {
      display: flex;
      align-items: center;
      font-size: 0.14rem;
      .text {
        color: #9ac0d9;
        width: 0.3rem;
        margin-left: 0.1rem;
        line-height: 1.2;
      }
    }
    .count {
      font-size: 0.24rem;
    }
    .noUnitText {
      font-size: 0.16rem;
    }
    .iconStyle {
      font-size: 0.24rem;
    }
    .cardRight {
      text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
    }
  }
}
.wrapHeader {
  height: 0.32rem;
}
.deviceId {
  color: #9ac0d9;
  font-size: 0.14rem;
  margin-left: auto;
}
.endTime {
  color: #d8e1f3;
  font-size: 0.12rem;
  margin-left: 0.16rem;
}
.floatBox {
  position: absolute;
  right: 0;
  top: 0.72rem;
  div {
    writing-mode: vertical-lr;
    letter-spacing: 5px;
    padding: 0.13rem 0.08rem 0.08rem 0.08rem;
    background: #223142;
    cursor: pointer;
    border-radius: 0.08rem 0 0 0.08rem;
    font-size: 0.12rem;
  }
}
</style>
