<template>
  <div
    class="konva"
    ref="konvaRef"
    :style="{ backgroundImage: `url(${background})` }"
  ></div>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import PlanViewCanvasMixin from '../../../mixin/plan-view-canvas';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';

@Component
export default class PlanViewCanvas extends mixins(PlanViewCanvasMixin) {
  deviceGroup = deviceTypeEnum[pageTypeEnum.liftMonitor].deviceGroup;
}
</script>

<style lang="less" scoped>
.konva {
  width: max-content;
  height: max-content;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}
</style>
